<template>
    <WalletLayout>
        <div class="row gy-3">
            <div class="col-md-12">
                <TopHeading :data="topHeading" />
            </div>
            <div class="col-12">
                <div class="card">
                    <div class="card-body shadow-sm">
                        <div class="row align-items-center gap-3">
                            <div class="col">
                                <div class="input-group search-input">
                                    <span class="input-group-text"><i class="fa-solid fa-magnifying-glass"></i></span>
                                    <input type="search" v-model="search" ref="searchInput" v-on:keyup="searchData()"
                                        placeholder="Search" class="form-control ">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="table-container table-height table-responsive custom-scrollbar">
                                    <table
                                        class="table border-bottom-0 text-secondary border-top-0 text-nowrap align-middle"
                                        id="table-list">
                                        <thead>
                                            <tr class="opacity">
                                                <th class="fw-semibold fs-14">Coin</th>
                                                <th class="fw-semibold fs-14">Amount</th>
                                                <th class="fw-semibold fs-14">Network</th>
                                                <th class="fw-semibold fs-14">Wallet Address</th>
                                                <th class="fw-semibold fs-14">Status</th>
                                                <th class="fw-semibold fs-14">Created At</th>
                                                <!-- <th class="text-end fw-semibold fs-14" scope="col">Operation</th> -->
                                            </tr>
                                        </thead>
                                        <tbody id="coins" v-if="loading">
                                            <tr class="align-middle" v-for="i in 10" :key="i">
                                                <td class="text-uppercase" v-for="j in 7" :key="j">
                                                    <VueSkeletonLoader type="react" :width="100" :height="15"
                                                        animation="wave" color="#adb5bd" wave-color="#fff"
                                                        :rounded="true" />
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody v-if="!loading && TransactionList.length > 0">
                                            <tr v-for="(item, index) of TransactionList" :key="index">
                                                <td>
                                                    <h6 class="m-0 fs-14 fw-500">{{ item.symbol }}</h6>
                                                </td>
                                                <td>
                                                    <h6 class="m-0 fs-14 fw-500">{{ item.amount }}</h6>
                                                </td>
                                                <td>
                                                    <h6 class="m-0 fs-14 fw-500">{{ item.chain_type }}</h6>
                                                </td>
                                                <td>
                                                    <h6 class="m-0 fs-14 fw-500">{{ item.user_wallet_address }}</h6>
                                                </td>
                                                <td>
                                                    <h6 class="m-0 fs-14 fw-500">{{ item.status }}</h6>
                                                </td>
                                                <td>
                                                    <h6 class="m-0 fs-14 fw-500">
                                                        {{ new Date(item.created_at).toLocaleDateString("en-US",
                                                            dateOptions) }}
                                                    </h6>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div v-if="h_totalData > 10">
                                        <pagination v-model="h_page" :records="h_totalData" :per-page="h_perPageData"
                                            :options="options" @paginate="getTxnList" />
                                    </div>
                                    <div v-if="!loading && TransactionList.length < 1"
                                        class="d-flex border-0 flex-column text-center justify-content-center my-5">
                                        <div>
                                            <img width="70" :src="require(`@/assets/images/icons/no-record.svg`)" />
                                        </div>
                                        <span class="fw-500 fs-14">No record found</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </WalletLayout>
</template>

<script>
// import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import WalletLayout from "@/layouts/WalletLayout.vue";
import Pagination from 'vue-pagination-2';
import ApiClass from "@/api/api";
import VueSkeletonLoader from "skeleton-loader-vue";
import TopHeading from "@/components/ui/TopHeading.vue";
export default {
    name: "TransactionHistory",
    components: {
        VueSkeletonLoader,
        Pagination,
        TopHeading,
        WalletLayout
        // DatePicker
    },
    data() {
        return {
            topHeading: {
                heading: 'Transaction History',
                subheading: 'Track deposit history for more growth',
                buttons: [
                    // { name: 'Spot Wallet', link: '/wallet/spot', class: 'text-primary border-primary' },
                    // {name:'Deposit', link:'/wallet/deposit', class:'btn-primary text-white'}
                ],
            },
            TransactionList: [],
            res: [],
            user_data: [],
            h_page: 1,
            h_totalData: 0,
            h_perPageData: 10,
            pastdays: '7 days',
            options: {
                edgeNavigation: false,
                chunksNavigation: false,
                chunk: 3,
                texts: false,
                format: false,
            },
            search:null,
            loading: false,
        }
    },
    methods: {
        searchData() {
            var search = document.getElementById("searchData").value.toUpperCase();
            if (search) {
                this.TransactionList = this.res.filter((e) => e.user_wallet_address.toUpperCase().includes(search));
            } else {
                this.TransactionList = this.res.filter((e) => e);
            }
        },

        async getTxnList(h_page) {
            var response = await ApiClass.getRequest(
                "deposit/get/" + this.user_data.id + "?per_page=" + this.h_perPageData + "&page=" + h_page,
                true, null, this.selectedOptions
            );

            if (response.data.status_code == 1) {
                this.loading = false;
                this.TransactionList = response.data.data;
                this.res = response.data.data;
                // this.h_totalData = 25;
                // this.h_perPageData = 10;
                this.h_totalData = response.data.data.total;
                this.h_perPageData = response.data.data.per_page;
            }
        },
    },
    async mounted() {
        this.loading = true;
        this.user_data = JSON.parse(localStorage.getItem("user"));
        this.getTxnList(1);

        // var withdraw = await ApiClass.getRequest("wallet-trans/get", true);
        // if (withdraw.data.status_code == 1) {
        //   this.withdrawList = withdraw.data.data;
        // }

    }
};
</script>
<style scoped>
#txn-nav-top .dropdown-menu li {
    cursor: pointer;
}

#txn-nav-top .date-option button:hover,
#txn-nav-top .filter-option:hover {
    color: var(--primary)
}

#txn-nav-top .date-option button,
#txn-nav-top .filter-option,
#txn-nav-top .date-option .dropdown-menu .dropdown-item {
    color: var(--text)
}

#txn-nav-top .date-option .btn.show {
    background-color: inherit !important;
}

.fliter-option-title {
    text-transform: capitalize;
}

#dateModal .modal-sm {
    max-width: min-content;
}

#btn-check-label {
    font-size: 0.8rem !important;
    font-weight: 500;
    color: var(--text);
    background-color: var(--bg5);
    border-radius: 2rem;
    border: none !important;
}

.filter-check:checked+.btn {
    background-color: var(--primary) !important;
    border: none !important;
    color: rgb(253, 253, 253) !important;
}

.btn-check:focus+.btn,
.btn:focus {
    outline: 0;
    box-shadow: none;
}

</style>